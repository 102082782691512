<template>
  <div class="dropdown-PDF">
    <v-btn
        color="save"
        elevation="0"
        min-width="130"
        min-height="40"
        class="white--text"
        :loading="isLoading"
        :disabled="isLoading"
        @click.stop="startyDownloading({ type: 'PDF' })"
    >
      <v-icon left size="26">mdi-file-pdf-box</v-icon>
      {{ language.componentLabel.labelReportType }}
    </v-btn>
<!--    <v-menu
        open-on-hover
        top
        offset-y
        close-on-click
        elevation="0"

    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="save"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            min-width="130"
            min-height="40"
            class="white&#45;&#45;text"
            :loading="loadingAndDisabled"
            :disabled="loadingAndDisabled"
        >
          <v-icon left size="26">mdi-file-pdf-box</v-icon>
          {{ language.componentLabel.labelReportType }}
        </v-btn>

      </template>
      <v-list>
        <v-list-item
            @click.stop="openPDF('PDFSimple')"
        >
          <v-list-item-title>{{ language.componentLabel.labelOverviewType }}</v-list-item-title>
        </v-list-item>
        <v-list-item
            @click.stop="openPDF('PDF')"
        >
          <v-list-item-title>{{ language.componentLabel.labelDetailedType }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>-->
  </div>
</template>

<script>
import requests from '@/js/requests';
import { getAccessToken, tokenRefresher } from "@/auth-tools"
import Cookies from "js-cookie"

export default {
  props: ["buttonAttrs"],
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    notifyUserAboutSessionEnd() {
      const TIME_FOR_READING = 3000
      this.$notify({
          group: 'foo',
          text: 'Your session has timed out. Please try again after re-login.',
          duration: TIME_FOR_READING,
          type: 'warning',
          speed: 600,
      });
      return new Promise(resolve => setTimeout(resolve, TIME_FOR_READING))
    },

    async startyDownloading({ type }) {
      if (!await requests.isJwtTokenValid()) {
        await this.notifyUserAboutSessionEnd();
        tokenRefresher.logout()
        return
      }

      const _at = getAccessToken()
      const url = `serve.php?f=testing&f2=testInfo&function=${type}&n_id=${this.buttonAttrs['n_id']}&n_id_key=${this.buttonAttrs['n_id_key']}&accessToken=${_at}`;

      try {
        const _resp = await requests.frameworkAxiosRequest({
          method: 'POST',
          url: 'serve.php',
          responseType: 'ajax',
        })

        if (!_resp?.data?.userInfo?.loggedIn) {
          await this.notifyUserAboutSessionEnd();
          tokenRefresher.logout()
          return
        }

        window.location.href = url;
        this.isLoading = true;
        await this.pollForDownloadStart();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false
      }
    },

    pollForDownloadStart() {
      const cookieName = "DownloadCompleteChecker"
      const expiresInMin = 2
      const expires = new Date(new Date().getTime() + expiresInMin * 60 * 1000)
      Cookies.set(cookieName, 42, { expires, path: "/" });

      return new Promise(resolve => {
        function checkCookie() {
          const cookie = Cookies.get(cookieName);
          if (!cookie) {
            resolve();
          } else {
            setTimeout(() => requestAnimationFrame(checkCookie), 2000);
          }
        }

        requestAnimationFrame(checkCookie);
      })
    }
  },
}
</script>

<style scoped>
.backgroundBlur {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: hsl(0deg 0% 100% / 70%);
  height: 100%;
  width: 100%;
}

/* .loaderImg{
   width: 45px;
   position: fixed;
   top: 50%;
   left: 50%;
   z-index: 999999;
 }*/
.loaderImg {
  width: 32px;
  position: absolute;
  z-index: 999999;
  bottom: 7px;
}
</style>

